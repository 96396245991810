import Graha1 from '../../../media/SCG/Graha/Graha (1).webp'
import Graha2 from '../../../media/SCG/Graha/Graha (2).webp'
import Graha3 from '../../../media/SCG/Graha/Graha (3).webp'
import Graha4 from '../../../media/SCG/Graha/Graha (4).webp'


const Grahaimages =[
Graha1,Graha2,Graha3,Graha4
]

export default Grahaimages;