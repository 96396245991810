import React from "react";
import "./ruko.scss";
import Produk1 from "./Produk1/Produk1.jsx";
import logoviola from "../../media/logo/graha.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Featuresimages from "./Features.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import momentum from "../../media/Features Ruko/Momentum.webp";

const rumah = () => {
  const settings1 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settings2 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const items = [
    "Clubhouse",
    "Kolam Renang",
    "Taman Bermain Anak-anak",
    "Ruang Multifungsi",
    "Taman Komunal",
    "Gerbang dengan Sistem Pengenalan Wajah",
    "Keamanan 24 Jam",
  ];
  return (
    <div id="graha" className="rumahgraha">
      <div className="logo-graha">
        <img src={logoviola} alt="logo-graha" />
      </div>
      <div className="container-desk">
        <div className="desk-graha">
          Gading Boulevard Commercial adalah sebuah area komersial yang terletak
          di Summarecon Crown Gading, Jakarta Utara. Area ini terdiri dari
          ruko-ruko yang dibangun di sepanjang jalan boulevard selebar 40 meter.
          Ruko-ruko tersebut memiliki luas tanah sekitar 80 m² dan memiliki tiga
          lantai. Gading Boulevard Commercial merupakan ruko perdana di kawasan
          Summarecon Crown Gading dan dijual mulai dari harga 3,1 miliar rupiah.
          Area komersial ini dikembangkan oleh PT Summarecon Agung Tbk, sebuah
          perusahaan pengembang properti yang berkantor pusat di Jakarta dan
          bisnis utamanya adalah pengembangan township.
        </div>
      </div>
      <div>
        <div className="Judul"></div>
        <div className="rumah-card">
          <Produk1 />
        </div>
      </div>
      <div className="fasilitas-graha">
        <div className="fasilitasgraha">
          <img src={momentum} alt="graha" />
        </div>
      </div>
      <div className="slidergraha">
        <div className="slider-graha">
          <Slider {...settings1}>
            {Featuresimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default rumah;
