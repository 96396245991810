import Future1 from '../../../src/media/future/Future (1).webp'
import Future2 from '../../../src/media/future/Future (2).webp'
import Future3 from '../../../src/media/future/Future (3).webp'
import Future4 from '../../../src/media/future/Future (4).webp'
import Future5 from '../../../src/media/future/Future (5).webp'


const Futureimages =[
Future1,Future2,Future3,Future4,Future5,
]

export default Futureimages;