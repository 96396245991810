import RegiaL1 from '../../../src/media/SCG Landscape (1)/Regia (1).webp'
import RegiaL2 from '../../../src/media/SCG Landscape (1)/Regia (2).webp'
import RegiaL3 from '../../../src/media/SCG Landscape (1)/Regia (3).webp'
import RegiaL4 from '../../../src/media/SCG Landscape (1)/Regia (4).webp'
import RegiaL5 from '../../../src/media/SCG Landscape (1)/Regia (5).webp'
import RegiaL6 from '../../../src/media/SCG Landscape (1)/Regia (6).webp'
import RegiaL7 from '../../../src/media/SCG Landscape (1)/Regia (7).webp'
import RegiaL8 from '../../../src/media/SCG Landscape (1)/Regia (8).webp'
import RegiaL9 from '../../../src/media/SCG Landscape (1)/Regia (9).webp'
import RegiaL10 from '../../../src/media/SCG Landscape (1)/Regia (10).webp'


const RegiaLimages =[
RegiaL1,RegiaL2,RegiaL3,RegiaL4,RegiaL5,RegiaL6,RegiaL7,RegiaL8,RegiaL9,RegiaL10
]

export default RegiaLimages;