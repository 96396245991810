import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import logosumban from "../../media/logo_scg 2.png";

const aboutsumban = () => {
  const aboutwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://eonna-bsdcity.com/%20()&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div id="about" className="about-sumban">
      <div className="containerlogo">
        <div className="logosumban">
          <img className="gambarsumban" src={logosumban} alt="logo sumban" />
        </div>
      </div>
      <div className="containerdesk">
        <div className="judul1">
          <h1>New City New Dynamic Living</h1>
        </div>
        <div className="desk">
          Summarecon crown gading adalah project terbaru dari Summarecon Agung
          Tbk. Summarecon Crown Gading ini luasnya 437 hektar. Konsep Summarecon
          Crown Gading adalah integrasi antara area urban dan nature dalam satu
          perpaduan yang harmonis, menciptakan kawasan hunian yang kaya dan
          vibrant untuk hidup dekat dengan alam. Summarecon Crown Gading juga
          menawarkan fasilitas yang lengkap seperti fasilitas kesehatan,
          fasilitas pendidikan, club house, Sport Arena, pusat peerbelanjaan,
          kawasan kuliner. Selain itu, Summarecon Crown Gading juga memiliki
          lokasi yang strategis dan mudah diakses dari Kelapa Gading.
        </div>
      </div>
    </div>
  );
};

export default aboutsumban;
