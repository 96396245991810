import Regia71 from '../../../media/SCG/Regia/7/Regia 7.webp'
import Regia72 from '../../../media/SCG/Regia/7/Regia 7A D.webp'
import Regia73 from '../../../media/SCG/Regia/7/Regia 7A P.webp'
import Regia74 from '../../../media/SCG/Regia/7/Regia 7B D.webp'
import Regia75 from '../../../media/SCG/Regia/7/Regia 7B P.webp'

const Regia7images =[
Regia71,Regia72,Regia73,Regia74,Regia75
]

export default Regia7images;