import ViolaF1 from '../../../src/media/Fasilitas/Viola (1).webp'
import ViolaF2 from '../../../src/media/Fasilitas/Viola (2).webp'
import ViolaF3 from '../../../src/media/Fasilitas/Viola (3).webp'
import ViolaF4 from '../../../src/media/Fasilitas/Viola (4).webp'
import ViolaF5 from '../../../src/media/Fasilitas/Viola (5).webp'
import ViolaF6 from '../../../src/media/Fasilitas/Viola (6).webp'


const ViolaFimages =[
ViolaF1,ViolaF2,ViolaF3,ViolaF4,ViolaF5,ViolaF6,
]

export default ViolaFimages;