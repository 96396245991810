import ViolaL1 from '../../../src/media/SCG Landscape (1)/Viola (1).webp'
import ViolaL2 from '../../../src/media/SCG Landscape (1)/Viola (2).webp'
import ViolaL3 from '../../../src/media/SCG Landscape (1)/Viola (3).webp'
import ViolaL4 from '../../../src/media/SCG Landscape (1)/Viola (4).webp'
import ViolaL5 from '../../../src/media/SCG Landscape (1)/Viola (5).webp'
import ViolaL6 from '../../../src/media/SCG Landscape (1)/Viola (6).webp'
import ViolaL7 from '../../../src/media/SCG Landscape (1)/Viola (7).webp'
import ViolaL8 from '../../../src/media/SCG Landscape (1)/Viola (8).webp'
import ViolaL9 from '../../../src/media/SCG Landscape (1)/Viola (9).webp'
import ViolaL10 from '../../../src/media/SCG Landscape (1)/Viola (10).webp'


const ViolaLimages =[
ViolaL1,ViolaL2,ViolaL3,ViolaL4,ViolaL5,ViolaL6,ViolaL7,ViolaL9,ViolaL10
]

export default ViolaLimages;