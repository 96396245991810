import JasmiaF1 from '../../../src/media/Fasilitas/Jasmia (1).webp'
import JasmiaF2 from '../../../src/media/Fasilitas/Jasmia (2).webp'
import JasmiaF3 from '../../../src/media/Fasilitas/Jasmia (3).webp'



const JasmiaFimages =[
JasmiaF1,JasmiaF2,JasmiaF3,
]

export default JasmiaFimages;