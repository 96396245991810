import React from "react";
import "./Viola.scss";
import Produk1 from "./Produk1/Produk1.jsx";
import logoviola from "../../media/logo/viola.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ViolaLimages from "./ViolaL.js";
import ViolaFimages from "./ViolaF.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import stokviola from "../../media/Siteplan SCG/Viola.webp";

const rumah = () => {
  const settings1 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settings2 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const items = [
    "Clubhouse",
    "Swimming Pool",
    "Children Playground",
    "Multifunction Room",
    "Communal Park",
    "Face Recognition System Gate",
    "Security 24 Hours",
  ];
  return (
    <div id="viola" className="rumah">
      <div className="logo-viola">
        <img src={logoviola} alt="logo-viola" />
      </div>
      <div className="heroviola">
        <div className="slider-viola">
          <Slider {...settings1}>
            {ViolaLimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
        </div>
        <div className="desk-viola">
          Viola Summarecon Crown Gading adalah salah satu proyek perumahan
          terbaru yang diluncurkan oleh PT Summarecon Agung Tbk di Summarecon
          Crown Gading, Jakarta Utara.Viola Residence merupakan salah satu tipe
          rumah yang ditawarkan di proyek ini, dengan opsi ATTIC ROOM. Kawasan
          ini mengintegrasikan pengembangan perumahan dengan pengembangan
          komersial yang didukung dengan berbagai fasilitas untuk penduduk
          kawasan.
        </div>
      </div>
      <div>
        <div className="Judul"></div>
        <div className="rumah-card">
          <Produk1 />
        </div>
      </div>
      <div className="fasilitas-viola">
        <div className="slider-fasilitas">
          <Slider {...settings2}>
            {ViolaFimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
        </div>
      </div>
      <ul className="fasilitas-list">
        {items.map((item, index) => (
          <li key={index}>
            <FontAwesomeIcon icon={faCheckDouble} color="white" />
            <span> &nbsp; {item}</span>
          </li>
        ))}
      </ul>
      <div className="stok-viola">
        <div className="gambar-stok">
          <img src={stokviola} alt="stok_viola" />
        </div>
        <div className="desk-stok">
          <h1>Informasi Unit</h1>
          <h2>Total Produk Dipasarkan : 208 Unit</h2>
          <h3>Sisa Stok Unit : 55 Unit</h3>
          <h4>Produk Terjual : 153 Unit</h4>
          <h5>Total Produk Belum Dipasarkan : 163 Unit</h5>
        </div>
      </div>
    </div>
  );
};

export default rumah;
