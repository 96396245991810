import Viola61 from '../../../media/SCG/Viola/6/Viola 6.webp'
import Viola62 from '../../../media/SCG/Viola/6/6 Deluxe.png'
import Viola63 from '../../../media/SCG/Viola/6/6 Premium.png'
import Viola64 from '../../../media/SCG/Viola/6/6 Attic.png'


const Viola6images =[
Viola61,Viola62,,Viola63,,Viola64
]

export default Viola6images;