import Kawasan1 from '../../../src/media/Kawasan/Kawasan (1).webp'
import Kawasan2 from '../../../src/media/Kawasan/Kawasan (2).webp'
import Kawasan3 from '../../../src/media/Kawasan/Kawasan (3).webp'
import Kawasan4 from '../../../src/media/Kawasan/Kawasan (4).webp'
import Kawasan5 from '../../../src/media/Kawasan/Kawasan (5).webp'
import Kawasan6 from '../../../src/media/Kawasan/Kawasan (6).webp'
import Kawasan7 from '../../../src/media/Kawasan/Kawasan (7).webp'
import Kawasan8 from '../../../src/media/Kawasan/Kawasan (8).webp'
import Kawasan9 from '../../../src/media/Kawasan/Kawasan (9).webp'
import Kawasan10 from '../../../src/media/Kawasan/Kawasan (10).webp'


const Kawasanimages =[
Kawasan1,Kawasan2,Kawasan3,Kawasan4,Kawasan5,Kawasan6,Kawasan7,Kawasan8,Kawasan9,Kawasan10
]

export default Kawasanimages;