import Viola71 from '../../../media/SCG/Viola/7/Viola 7.webp'
import Viola72 from '../../../media/SCG/Viola/7/7 Deluxe.png'
import Viola73 from '../../../media/SCG/Viola/7/7 Premium.png'
import Viola74 from '../../../media/SCG/Viola/7/7 Attic.png'


const Viola7images =[
Viola71,Viola72,Viola73,Viola74
]

export default Viola7images;