import Features1 from '../../../src/media/Features Ruko/Features (1).webp'
import Features2 from '../../../src/media/Features Ruko/Features (2).webp'
import Features3 from '../../../src/media/Features Ruko/Features (4).webp'
import Features4 from '../../../src/media/Features Ruko/Features (5).webp'


const Featuresimages =[
Features1,Features2,Features3,Features4
]

export default Featuresimages;