import Jasmia91 from '../../../media/SCG/Jasmia/9/Jasmia 9.webp'
import Jasmia92 from '../../../media/SCG/Jasmia/9/Jasmia 9 D.webp'
import Jasmia93 from '../../../media/SCG/Jasmia/9/Jasmia 9 P.webp'
import Jasmia94 from '../../../media/SCG/Jasmia/9/Jasmia 9 Attic.png'


const Jasmia9images =[
Jasmia91,Jasmia92,Jasmia93,Jasmia94
]

export default Jasmia9images;